<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="biomicroscopia"
  >
  <div class="form-group">
    <b-col cols="12">
      <toggle-button
        class="autoFillBtn"
        v-model="autoCompleted"
        :sync="true"
        :height="24"
        :disabled="!canEdit"
        :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
        @change="autoFill"
      />
      <span class="autoFillBtnLabel">Marcar campos em branco como “sem alterações”</span>
    </b-col>
    <b-col cols="12">
        <toggle-button
          class="autoFillBtn"
          v-model="padrao"
          :sync="true"
          :height="24"
          :disabled="!canEdit || !useStandard"
          :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
          @change="setPadrao"
        />
        <span class="autoFillBtnLabel">Preencher com registro padrão</span>
    </b-col>
    <b-col cols="12">
        <toggle-button
          class="autoFillBtn"
          :value="simplified"
          :sync="true"
          :height="24"
          @change="handleUseSimplified"
          :disabled="!canEdit"
          :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
        />
        <span class="autoFillBtnLabel">Visualização padrão simplificada</span>
      </b-col>
  </div>
  <b-col cols="12" v-if="simplified" >
    <b-row>
      <b-col>
        <div class="form-group ">
          <b-row>
            <b-col cols="12">
              <div class="input-eye mb-4">
                <span for="simplified-right" class="tag-eye">
                  <EyeFill /> D
                </span>
                  <v-text-area
                    id="olhoDireito"
                    rows="1"
                    :value="form.fields.olhoDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('olhoDireito', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
            </b-col>
            <b-col cols="12">
              <div class="input-eye">
                <span for="simplified-right" class="tag-eye">
                  <EyeFill /> E
                </span>
                  <v-text-area
                    id="olhoEsquerdo"
                    rows="1"
                    :value="form.fields.olhoEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('olhoEsquerdo', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <button
        v-if="canEdit"
        class="btn blue-underline button-copy"
        @click="() =>copyEyeValues('olhoDireito','olhoEsquerdo')"
        v-b-tooltip.hover title="Copiar valores O.D para O.E"
      >
        <v-copy-icon/>
      </button> 
    </b-row>
  </b-col>
    <b-col cols="12" v-if="!simplified">
      <b-row>
        <b-col>
          <div class="form-group">
            <label>Cílios e pálpebras</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <v-text-area
                    id="bio-ciliosEPalpebrasDireito"
                    rows="1"
                    :value="form.fields.ciliosEPalpebrasDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('ciliosEPalpebrasDireito', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control "
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <v-text-area
                    id="bio-ciliosEPalpebrasEsquerdo"
                    rows="1"
                    :value="form.fields.ciliosEPalpebrasEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('ciliosEPalpebrasEsquerdo', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click=" () => copyEyeValues('ciliosEPalpebrasDireito', 'ciliosEPalpebrasEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <v-copy-icon/>
        </button>
      </b-row>

      <b-row>
        <b-col>
          <div class="form-group">
            <label>Conjuntiva</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <v-text-area
                    id="bio-conjuntivaDireito"
                    rows="1"
                    :value="form.fields.conjuntivaDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('conjuntivaDireito', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <v-text-area
                    id="bio-conjuntivaEsquerdo"
                    rows="1"
                    :value="form.fields.conjuntivaEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('conjuntivaEsquerdo', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click="() => copyEyeValues('conjuntivaDireito', 'conjuntivaEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <v-copy-icon/>
        </button>
      </b-row>

      <b-row>
        <b-col>
          <div class="form-group">
            <label>Córnea</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <v-text-area
                    id="bio-corneaDireito"
                    rows="1"
                    :value="form.fields.corneaDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('corneaDireito', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <v-text-area
                    id="bio-corneaEsquerdo"
                    rows="1"
                    :value="form.fields.corneaEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('corneaEsquerdo', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click="() => copyEyeValues('corneaDireito', 'corneaEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <v-copy-icon/>
        </button>
      </b-row>
      
      <b-row>
        <b-col>
          <div class="form-group">
            <label>Câmara anterior</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <v-text-area
                    id="bio-camaraAnteriorDireito"
                    rows="1"
                    :value="form.fields.camaraAnteriorDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('camaraAnteriorDireito', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <v-text-area
                    id="bio-camaraAnteriorEsquerdo"
                    rows="1"
                    :value="form.fields.camaraAnteriorEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('camaraAnteriorEsquerdo', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click="() => copyEyeValues('camaraAnteriorDireito','camaraAnteriorEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <v-copy-icon/>
        </button>
      </b-row>

      <b-row>
        <b-col>
          <div class="form-group">
            <label>Íris</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <v-text-area
                    id="bio-irisDireito"
                    rows="1"
                    :value="form.fields.irisDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('irisDireito', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <v-text-area
                    id="bio-irisEsquerdo"
                    rows="1"
                    :value="form.fields.irisEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('irisEsquerdo', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click="() => copyEyeValues('irisDireito', 'irisEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <v-copy-icon/>
        </button>
      </b-row>

      <b-row>
        <b-col>
          <div class="form-group">
            <label>Pupila</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <v-text-area
                    id="bio-pupilaDireito"
                    rows="1"
                    :value="form.fields.pupilaDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('pupilaDireito', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <v-text-area
                    id="bio-pupilaEsquerdo"
                    rows="1"
                    :value="form.fields.pupilaEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('pupilaEsquerdo', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click="() => copyEyeValues('pupilaDireito', 'pupilaEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <v-copy-icon/>
        </button>
      </b-row>

      <b-row>
        <b-col>
          <div class="form-group">
            <label>Cristalino/Lente Intraocular</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <v-text-area
                    id="bio-cristalinoDireito"
                    rows="1"
                    :value="form.fields.cristalinoDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('cristalinoDireito', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <v-text-area
                    id="bio-cristalinoEsquerdo"
                    rows="1"
                    :value="form.fields.cristalinoEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('cristalinoEsquerdo', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click=" () => copyEyeValues('cristalinoDireito', 'cristalinoEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <v-copy-icon/>
        </button>
      </b-row>

      <b-row>
        <b-col>
          <div class="form-group">
            <label>Vítreo anterior</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <v-text-area
                    id="bio-vitreoDireito"
                    rows="1"
                    :value="form.fields.vitreoDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('vitreoDireito', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <v-text-area
                    id="bio-vitreoEsquerdo"
                    rows="1"
                    :value="form.fields.vitreoEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('vitreoEsquerdo', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click="() => copyEyeValues('vitreoDireito', 'vitreoEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <v-copy-icon/>
        </button>
      </b-row>

      <b-row>
        <b-col>
          <div class="form-group mb-0">
            <label>Biomicroscopia de fundo</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <v-text-area
                    id="bio-biomicroscopiaDeFundoDireito"
                    rows="1"
                    :value="form.fields.biomicroscopiaDeFundoDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('biomicroscopiaDeFundoDireito', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <v-text-area
                    id="bio-biomicroscopiaDeFundoEsquerdo"
                    rows="1"
                    :value="form.fields.biomicroscopiaDeFundoEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('biomicroscopiaDeFundoEsquerdo', value)"
                    @blur="debounceUpdateMR(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click=" () =>copyEyeValues('biomicroscopiaDeFundoDireito','biomicroscopiaDeFundoEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <v-copy-icon/>
        </button>
      </b-row>
    </b-col>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import EyeFill from '@/assets/icons/eye-fill.svg'
import TextArea from '@/components/General/TextArea'
import Copy from '@/assets/icons/copy.svg'
import { debounce } from 'lodash';

export default {
  components: {
    FormWrapper,
    EyeFill,
    'v-text-area': TextArea,
    'v-copy-icon' : Copy,
  },
  async mounted() {
    await this.getStandards()
    this.debounceUpdateMR = debounce(this.updateMedicalRecord, 300)
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.biomicroscopia,
      selectedForm: state => state.attendance.form.selected
    })
  },
  data() {
    return {
      useStandard: false,
      autoCompleted: false,
      padrao: false,
      simplified: false,
      user: JSON.parse(localStorage.getItem('user')),
      standard: {},
    }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/biomicroscopia', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    async getStandards() {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getStandards(this.user.id)
        this.standard = response.data.find(std => std.type === "biomicroscopia")
        this.useStandard = this.standard?.active
        if (this.canEdit && this.useStandard) {
          this.simplified = !!this.standard.simplified
          this.updateForm('simplified', !!this.standard.simplified)
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    canFillData (currentValue) {
      return (
        currentValue === null 
        || currentValue === 'sem alterações' 
        || (typeof currentValue === 'string' && currentValue.trim() === '') 
      )
    },
    autoFill() {
      const newValue = this.autoCompleted ? 'sem alterações' : null;
      Object.keys(this.form.fields).filter(el => el !== 'simplified').map(key => {
        const currentValue = this.form.fields[key]
        if( this.canFillData(currentValue) ) {
          this.updateForm(key, newValue)
        }
      })
      this.updateMedicalRecord(this.form)
    },
    copyEyeValues(rightEyeFieldName, leftEyeFieldName) {
      this.updateForm(leftEyeFieldName, this.form.fields[rightEyeFieldName])
      this.debounceUpdateMR(this.form)
    },
    handleSaveSimplified () {
      const keys = Object.keys(this.form.fields).filter(el => el !== 'simplified')
      const canSave = keys.some(key => {
        return ( this.form.fields[key] )
      })

      if (canSave) 
        this.updateMedicalRecord(this.form)
    },
    handleUseSimplified () {
      this.simplified = !this.simplified
      this.updateForm('simplified', this.simplified)
      this.handleSaveSimplified()
      
    },
    canSetDefault (currentValue, standardKey) {
      return (
        currentValue === null 
        || ( this.standard && currentValue === this.standard?.value[standardKey] )
        || currentValue === 'sem alterações' 
        || (typeof currentValue === 'string' && currentValue.trim() === '')
      )
    },

    setPadrao(){
      for (const standardKey in this.standard.value){
        for (const key in this.form.fields) {
          if(key.includes(standardKey)){
            const newValue = this.padrao ? this.standard.value[standardKey] : (this.autoCompleted ? 'sem alterações' : null);
            const currentValue = this.form.fields[key]
            if(!this.standard.value[standardKey] || key === 'simplified') continue
            if (this.canSetDefault(currentValue, standardKey)) {
              this.updateForm(key, newValue)
            }
          }
        }
      }
      this.updateMedicalRecord(this.form)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    }
  },
  watch: {
    form() {
      this.autoCompleted = Object.keys(this.form.fields).some(key => this.form.fields[key] === 'sem alterações')
      if(this.standard && this.standard.value)
        this.padrao = Object.keys(this.form.fields).some(key => Object.keys(this.standard.value).some(stdKey => key.includes(stdKey) && this.standard.value[stdKey] && this.form.fields[key] === this.standard.value[stdKey]) )
    },
   'form.fields.olhoDireito': function (value) {
    if(value && this.form?.fields?.simplified) {
        this.simplified = true
      }
   },
   'form.fields.olhoEsquerdo': function (value) {
      if(value && this.form?.fields?.simplified) {
        this.simplified = true
      }
    },
    selectedForm: {
      immediate: true,
      deep: true,
      handler() {
        if(this.selectedForm?.label === 'Programa Glaucoma SUS'){
          this.simplified = true
          this.updateForm('simplified', !!this.simplified)
        }
      }
    },
   deep: true
  }
}
</script>